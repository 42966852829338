import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import Modal from '../../../components/Modal';
import { getAccountStructure } from '../../../redux/manager/accountStructures/accountStructureActions';
import { connect } from 'react-redux';


const ApprovalModal = ({onConfirmApproveClick, trigger, register, errors, viewAsUser, expenseHeader, toggleApproveModal, approveExpenseHeaderLoading, onApprove}) => {

  useEffect(() => {

    if (expenseHeader != null) {
      fetchAccountStructure();
    }
    
  }, [expenseHeader])


  const [accountStructure, accountStructureLoading, accountStructureLoaded, accountStructureError, doAccountStructureFetch, setRolesResult] = useApi();

  const fetchAccountStructure = () => {
    doAccountStructureFetch(getAccountStructure, {query: {branch: expenseHeader.branch}});
  }

  return (
    <Modal size="small" focusTrap={false} toggleModal={toggleApproveModal}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">Approve Expense Report</h2>
        <div onClick={toggleApproveModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        {/* <pre>
          branch: {JSON.stringify(expenseHeader?.branch, null, 2)}
        </pre> */}
        {/* <pre>
          accountStructure: {JSON.stringify(accountStructure, null, 2)}
        </pre> */}
        {/* <pre>
          expenseHeader.bu: {JSON.stringify(expenseHeader?.bu, null, 2)}
        </pre> */}
        {/* <pre>
          expenseHeader.cc: {JSON.stringify(expenseHeader?.cc, null, 2)}
        </pre> */}

        {(viewAsUser.roleid !== 5 && viewAsUser.roleid !== 9) &&
          <>
            {expenseHeader &&
              <>
                <input  
                  {...register(`bu`, {
                    validate: v => {
                      return true;
                    }
                  })}  
                  type="hidden"
                  name="bu"
                  defaultValue={expenseHeader?.bu || ""} 
                />
                <input  
                  {...register(`cc`, {
                    validate: v => {
                      return true;
                    }
                  })}  
                  type="hidden"
                  name="cc"
                  defaultValue={expenseHeader?.cc || ""} 
                />
              </>
            }
            <div>
              Are you sure you want to approve this expense report?
            </div>
          </>
        }
        {(expenseHeader && (viewAsUser.roleid === 5 || viewAsUser.roleid === 9)) &&
          <>
            {expenseHeader.projectgroup != "INDIRECT" &&
              <>
                <ul>
                  <li>BU: {expenseHeader.bu}</li>
                  <li>CC: {expenseHeader.cc}</li>
                </ul>

                
              </>
            }
            {(expenseHeader.projectgroup == "INDIRECT") &&
              <>
                <label className="margin-bottom-1" htmlFor="bu">
                  <div className="label-text">Business Unit (BU)</div>
                  {accountStructureLoading &&
                      <div className="editable-cont loading">
                        <div className="spinner"></div>
                      </div>
                    }
                    {!accountStructureLoading && accountStructure &&
                      <select  
                        {...register(`bu`, {
                          validate: v => {
                            if (!v) return "Please select a business unit."
                            return true;
                          }
                        })}  
                        name="bu"
                        defaultValue={expenseHeader?.bu || ""} 
                      >
                        <option value="">Select business unit</option>
                        {accountStructure.business_units?.map((bu, index) => {
                          return (<option value={bu}>{bu}</option>)
                        })}
                      </select>
                    }

                  {errors?.bu &&
                    <div className="error-cont">{errors?.bu?.message}</div>
                  }
                </label>

    



                <label htmlFor="cc">
                  <div className="label-text">Cost Center (CC)</div>
                  {accountStructureLoading &&
                      <div className="editable-cont loading">
                        <div className="spinner"></div>
                      </div>
                    }
                    {!accountStructureLoading && accountStructure &&
                      <select  
                        {...register(`cc`, {
                          validate: v => {
                            if (!v) return "Please select a cost center."
                            return true;
                          }
                        })}  
                        name="cc"
                        defaultValue={expenseHeader?.cc || ""} 
                      >
                        <option value="" >Select cost center</option>
                        {accountStructure.cost_centers?.map((cc, index) => {
                          return (<option value={cc}>{cc}</option>)
                        })}
                      </select>
                    }

                  {errors?.cc &&
                    <div className="error-cont">{errors?.cc?.message}</div>
                  }
                </label>
              </>
            }
          </>
        }

        {/* {expenseHeader.projectgroup} */}
        

        
      </div>

      <div className='modal-footer flex-cont align-center'>
        <div onClick={onConfirmApproveClick} className={`${approveExpenseHeaderLoading ? 'disabled' : ''} margin-right-1 button`} >
          Confirm Approval
          {approveExpenseHeaderLoading &&
            <div className="spinner button-spinner"></div>
          }
        </div>
        <div onClick={toggleApproveModal} className={`${approveExpenseHeaderLoading ? 'disabled' : ''} button ghost`}>Cancel</div>
      </div>
    </Modal>
  )
}


function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
    currentUser: state.authState.currentUser,
    viewAsUser: state.authState.viewAsUser
  };
} 

export default connect(mapState)(ApprovalModal);