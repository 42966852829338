import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../../api';
import { connect } from 'react-redux';
import { currencies } from './currencies';
import { DateTime } from 'luxon';
import {
  Link, useNavigate, useParams,
} from 'react-router-dom';

import { usePrevious } from '../../../hooks/usePrevious';

import { useForm, useWatch } from 'react-hook-form';
import TH from '../TH';
import { useSortBudgets, toNum, isNumber } from '../useSortBudgets';
import { deleteExpenseHeader, getExpenseHeader, createExpenseHeader, updateExpenseHeader, submitExpenseHeader, approveExpenseHeader, returnExpenseHeader, getExpenseCategories, getTaxes, getMainAccounts } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { isNumeric } from '../../../utils';
import ExpenseLine from './ExpenseLine';
import { getMyBudgetHeadersForExpenseReports } from '../../../redux/projects/projectActions';
import Modal from '../../../components/Modal';
import Upload from './Upload';
import uploadFileToBlob, { isStorageConfigured, getBlobsInContainer } from './azure-storage-blob';
import DisplayImagesFromContainer from './ContainerImages';
// import ProjectSelect from './ProjectSelect';
import { InteractiveBrowserCredential } from "@azure/identity";

import { getAccountStructures } from '../../../redux/manager/accountStructures/accountStructureActions';
import ApprovalModal from './ApprovalModal';


const storageConfigured = isStorageConfigured();




const ProjectSelect = ({viewAsUser, inputsDisabled, errors, register, projects, projectsLoading, existingExpenseHeader}) => {

  const projectInList = (projectID) => {

    if (!projects) return false;
    if (projectID == null || projectID == "") return false;

    const ids = projects.map(p => p.projectid?.toString());

    return ids.includes(projectID.toString());

  }

  const shouldShowSelect = () => {
    if (projectsLoading) return false;
    if (!existingExpenseHeader) return true;

    if (existingExpenseHeader && (existingExpenseHeader.workerrecid != viewAsUser.workerrecid)) return false;

    if (existingExpenseHeader.projectid == null || existingExpenseHeader.projectid.length === 0) return true;

    if (!projectInList(existingExpenseHeader?.projectid)) {
      return false;
    }

    return true;
  }


  return (
    <>
      {/* <div>
        {shouldShowSelect().toString()}
        {JSON.stringify((existingExpenseHeader?.projectid), null, 2)}
      </div> */}
      {projectsLoading &&
        <div className="dummy-select full editable-cont loading">
          <div className="spinner"></div>
        </div>
      }


      <input type="hidden" name={`projectgroup`} value={existingExpenseHeader ? existingExpenseHeader.projectgroup : ""}  />

      {!shouldShowSelect() && !projectsLoading &&
        <div className="">
          {existingExpenseHeader?.projectid} - {existingExpenseHeader?.projectname}

          <input type="hidden" name={`projectid`} value={existingExpenseHeader ? existingExpenseHeader.projectid : ""}  />
          
        </div>
      }




      {shouldShowSelect()  &&
        <>
        {/* <div>
          {JSON.stringify(projectInList(existingExpenseHeader?.projectid), null, 2)}
        </div>
        <pre>
          {JSON.stringify(projects, null, 2)}
        </pre> */}
        <select
          {...register(`projectid`, {
            validate: v => {
              if (!v) return "Select a project."
              return true;
            }
          })}  
          name={`projectid`}
          defaultValue={existingExpenseHeader ? existingExpenseHeader.projectid : ""} 
          className={`full ${(inputsDisabled) && 'disabled-input'} `}
        >
          <option value="" disabled>Select Project</option>
          
          {projects?.map(project => {
            return (
              <option value={project.projectid}>
                {project.projectid} - {project.projectname}
              </option>
            )
          })}
        </select>
        {errors?.projectid &&
          <div className="error-cont">{errors?.projectid?.message}</div>
        }
        </>
      }
    </>
  )
}

const ExpenseForm = ({existingExpenseHeader, viewAsUser, currentUser, currentCompany}) => {
  
  const navigate = useNavigate("/");


  const { setError, control, setValue, watch, unregister, register, handleSubmit, formState: {errors}, getValues, trigger, reset, clearErrors} = useForm({
    // mode: 'onChange',
    // reValidateMode: 'onChange',
  });
  const projectIDWatch = useWatch({defaultValue: existingExpenseHeader?.projectid?.toString() || null, control, name: 'projectid'});
  // const projectGroupWatch = useWatch({defaultValue: existingExpenseHeader?.projectgroup?.toString() || null, control, name: 'projectgroup'});
  const billableWatch = useWatch({defaultValue: existingExpenseHeader?.isbillable?.toString() || "false", control, name: 'isbillable'});
  const totalsWatch = useWatch({ control, name: `totalexpenses` });
  const lineWatch = useWatch({defaultValue: existingExpenseHeader?.expense_lines, control, name: `expense_lines_attributes` });
  const hbaLineWatch = useWatch({ control, name: `hba_expense_lines_attributes` });

  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();
  const [exchangeRates, setExchangeRates] = useState(null);
  const currencyWatch = useWatch({defaultValue: existingExpenseHeader?.exchangecurrency, control, name: `exchangecurrency` });
  const lessCashWatch = useWatch({defaultValue: existingExpenseHeader?.lesscashadvance, control, name: 'lesscashadvance'})
  const getMyProjects = () => {
    doProjectsFetch(getMyBudgetHeadersForExpenseReports, {query: {
      headers_only: true,
      admin_all: true,
      expenseheaderid: existingExpenseHeader?.id,
    }});
  }

  const [currentProjectGroup, setCurrentProjectGroup] = useState(null);

  const getProjectGroup = () => {

    if (projects == null || projects.length === 0) return null;
    if (projectIDWatch == null || projectIDWatch.length === 0) return null



    const filtered = projects.filter(project => project.projectid === projectIDWatch);

    if (filtered != null && filtered.length !== 0) {
      return filtered[0].projectgroup;
    }
    

    return null;
  }

  useEffect(() => {
    if (projectIDWatch && projects) {
      setCurrentProjectGroup(getProjectGroup());
    }

  }, [projectIDWatch, projects])

  const [expenseCategories, expenseCategoriesLoading, expenseCategoriesLoaded, expenseCategoriesError, doExpenseCategoriesFetch, setExpenseCategoriesResult] = useApi();

  const [mainAccounts, mainAccountsLoading, mainAccountsLoaded, mainAccountsError, doMainAccountsFetch, setMainAccountsResult] = useApi();
  
  const [taxes, taxesLoading, taxesLoaded, taxesError, doTaxesFetch, setTaxesResult] = useApi();

  const [filteredCategories, setFilteredCategories] = useState(null);


  useEffect(() => {

    setFilteredCategories(filterCats());
    
    
  }, [expenseCategories, billableWatch, currentProjectGroup]);


  const fetchTaxes = () => {
    doTaxesFetch(getTaxes);
  }

  const fetchMainAccounts = () => {
    doMainAccountsFetch(getMainAccounts);
  }


  const fetchExpenseCategories = () => {

    doExpenseCategoriesFetch(getExpenseCategories);
  }

  const filterCats = () => {

    if (!expenseCategories || !currentProjectGroup) {
      return null
    }

    let filtered = null;

    

    

    if (currentProjectGroup === "INDIRECT") {
      filtered = expenseCategories;
      filtered = filtered.filter(cat => cat.glnumber.toString().substring(0, 1) === "6")
    }
    else {

      if (billableWatch === "true") {
        filtered = expenseCategories.filter(cat => cat.linepropertyid === "Charge" || cat.linepropertyid === "All")
      }
      else if (billableWatch === "false") {
        filtered = expenseCategories.filter(cat => cat.linepropertyid === "Non-Charge"  || cat.linepropertyid === "All")
      }


      filtered = filtered.filter(cat => cat.glnumber.toString().substring(0, 1) === "5" || cat.glnumber.toString().substring(0, 3) === "117")
    }

    return filtered;
    
    
  }


  useEffect(() => {


    getMyProjects();
    fetchExpenseCategories();
    // fetchMainAccounts();
    fetchTaxes();


    getExchangeRates();
    
  }, []);

  useEffect(() => {
    if (currencyWatch) {
      getExchangeRates();
    }
    
  }, [currencyWatch]);



  const getExchangeRates = async () => {
    if (!currencyWatch) return;

    const stored = localStorage.getItem(`exchangeRates${currencyWatch}`);
    
    if (stored) {

      const parsed = JSON.parse(stored);

      const millis = Date.now() - (parsed['time_last_update_unix']*1000.0);
      const hours = millis / (1000 * 60 * 60);

      if (hours >= 24) {
        fetchExchangeRates();
      }
      else {
        setExchangeRates(parsed);
      }

    }
    else {
      fetchExchangeRates();
    }
  }

  const fetchExchangeRates = async () => {
    if (!currencyWatch) return;

    const result = await fetch(`https://open.er-api.com/v6/latest/${currencyWatch}`);
    const json = await result.json();

    localStorage.setItem(`exchangeRates${currencyWatch}`, JSON.stringify(json));
    setExchangeRates(json);
  }


  const readOnly = false;
  const project = {};



  
  const sumFields = (fieldNames=[], hba=false) => {

    let lines = null;

    if (hba) {
      lines = hbaLineWatch;
    }
    else {
      lines = lineWatch;
    }

    let runningTotal = 0;

    fieldNames.forEach(fieldName => {
      const array = lines?.map(o => o[fieldName]);

      if (!array || array.length === 0) return 0.0
  
  
      const sum = array.reduce((previous, current) => {
  
        if (current === null || current === undefined) {
          return previous;
        }
  
        const currentNoCommas = current?.toString()?.replace(/,/g, '');
        const currentNumber = Number(currentNoCommas);
        const currentParsed = parseFloat(currentNoCommas);
  
  
        if (isNaN(currentParsed) || isNaN(currentNumber)) {
          return previous;
        }
  
        return currentParsed + previous;
      }, 0);

      runningTotal += parseFloat(sum);

    })
    

    return parseFloat(runningTotal);
  }

  
  const sumTotals = (hba=false) => {
    if (!totalsWatch || totalsWatch.length === 0) return 0.0


    const sum = totalsWatch.reduce((previous, current) => {

      if (current === null || current === undefined) {
        return previous;
      }

      const currentNoCommas = current?.toString()?.replace(/,/g, '');
      const currentNumber = Number(currentNoCommas);
      const currentParsed = parseFloat(currentNoCommas);


      if (isNaN(currentParsed) || isNaN(currentNumber)) {
        return previous;
      }

      return currentParsed + previous;
    }, 0);

    return parseFloat(sum);

  }

  const [selectedIndex, setSelectedIndex] = useState(null);



  const initialLineCount = () => {
    return (
      existingExpenseHeader?.expense_lines?.length || 0
    )
  }
  const initialRowArray = () => {
    return (
      [...Array(initialLineCount()).keys()]
    )
  }

  const initialBlobHash = () => {

  }

  const [rows, setRows] = useState(initialRowArray());
  const [removedIndexes, setRemovedIndexes] = useState([]);
  const [userCount, setUserCount] = useState(initialLineCount());
  const [nonHiddens, setNonHiddens] = useState([]);


  useEffect(() => {
    if (existingExpenseHeader) {
      setRows(initialRowArray());
      setUserCount(initialLineCount());

      setBlobs(o => {
        let oldBlobs = o;

        for (let i = 0; i < existingExpenseHeader?.expense_lines?.length || 0; i++) {
          oldBlobs[i] = existingExpenseHeader.expense_lines[i];
        }

        return oldBlobs;
      });
    }

  }, [existingExpenseHeader]);


  
  // const mealsArray = mealsWatch?.map(o => o.meals);

  const addRowClicked = () => {
    clearErrors('hasLines');
    setRows(o => [...o, userCount]);
    setValue(`expense_lines_attributes.${userCount}`, {})
    setUserCount(o => o+1);    
  }

  useEffect(() => {
    setNonHiddens(
      rows.filter(r => !removedIndexes.includes(r))
    )
  }, [rows, removedIndexes]);

  const [uploadModalOpened, setUploadModalOpened] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpened(o => !o);
  }



  const [createdExpenseHeader, createExpenseHeaderLoading, createExpenseHeaderLoaded, createExpenseHeaderError, doCreateExpenseHeaderFetch, setCreateExpenseHeaderResult] = useApi();


  const [updatedExpenseHeader, updateExpenseHeaderLoading, updateExpenseHeaderLoaded, updateExpenseHeaderError, doUpdateExpenseHeaderFetch, setUpdateExpenseHeaderResult] = useApi();

  const [submittedExpenseHeader, submitExpenseHeaderLoading, submitExpenseHeaderLoaded, submitExpenseHeaderError, doSubmitExpenseHeaderFetch, setSubmitExpenseHeaderResult] = useApi();

  const [approvedExpenseHeader, approveExpenseHeaderLoading, approveExpenseHeaderLoaded, approveExpenseHeaderError, doApproveExpenseHeaderFetch, setApproveExpenseHeaderResult] = useApi();

  const [returnedExpenseHeader, returnExpenseHeaderLoading, returnExpenseHeaderLoaded, returnExpenseHeaderError, doReturnExpenseHeaderFetch, setReturnExpenseHeaderResult] = useApi();

  const [returnNote, setReturnNote] = useState(null);
  const [returnModalOpened, setReturnModalOpened] = useState(false);

  const returnButtonClicked = () => {
    toggleReturnModal();
  }

  const toggleReturnModal = () => {
    setReturnModalOpened(o => !o);
  }

  const [submitModalOpened, setSubmitModalOpened] = useState(false);
  const [submitNote, setSubmitNote] = useState("");

  const toggleSubmitModal = () => {
    setSubmitModalOpened(old => !old);
  }

  const [errorModalOpened, setErrorModalOpened] = useState(false);

  const toggleErrorModal = () => {
    setErrorModalOpened(o => !o);
  }

  const hasLines = () => {
    const lines = getValues('expense_lines_attributes');


    if (lines == null) return false;



    if (lines.length === 0) return false;

  
    

    if (lines.filter(l => !l._destroy).length === 0) return false 

    return true;


  }

  const hasErrors = () => {
    return Object.keys(errors).length !== 0
  } 

  const onSubmitClicked = async () => {


    // const t = await trigger();
    // 
    if (!hasLines()) {
      setError('hasLines', { type: 'custom', message: 'Please add a line' });
      toggleErrorModal();
      return;
    }
    else {
      clearErrors('hasLines');
    }
    
    const t = await trigger();

// t && !
    if (t) {
      toggleSubmitModal();
    }
    else {
      toggleErrorModal()
    }
  }

  const [approveModalOpened, setApproveModalOpened] = useState(false);
  const toggleApproveModal = () => {
    setApproveModalOpened(o => !o);
  }

  const onApproveButtonClicked = () => {
    toggleApproveModal();
  }

  const onConfirmApproveClick = async () => {
    const t = await trigger();

    if (t) {
      onApprove();
    }
    else {
      toggleErrorModal()
    }
  }

  const onApprove = (d) => {

    toggleApproveModal();
    let data = getValues();

    const payload = {
      id: data.id,
      expense_header: data
    }

    doApproveExpenseHeaderFetch(approveExpenseHeader, {payload: payload});
  }

  const onReturn = (d) => {
    let data = getValues();

    data.returnnote = returnNote;


    const payload = {
      id: data.id,
      expense_header: data
    }

    doReturnExpenseHeaderFetch(returnExpenseHeader, {payload: payload});
  }

  ///////




  const somethingLoading = updateExpenseHeaderLoading || createExpenseHeaderLoading || submitExpenseHeaderLoading || approveExpenseHeaderLoading || returnExpenseHeaderLoading;


  const inputsDisabled = () => {
    if (somethingLoading) return true;

    if (existingExpenseHeader == null || existingExpenseHeader?.id == null) {
      return false
    }


    if (existingExpenseHeader?.expenseheaderstatusid == 0 || 
        existingExpenseHeader?.expenseheaderstatusid == 1 || 
        existingExpenseHeader?.expenseheaderstatusid == 6) {
      return false;
    }

    if (existingExpenseHeader?.expenseheaderstatusid == 9 || existingExpenseHeader?.expenseheaderstatusid == 10) {
      return true;
    }

    if (viewAsUser.roleid == 5 || viewAsUser.roleid == 9) {
      return false;
    }


    return true;
    



  }




  const dueEmployee = () => {
    if (lessCashWatch == null || lessCashWatch.toString().length === 0) {
      return Math.max(0, parseFloat(sumFields(["meals"])))
    }
    return Math.max(0, (parseFloat(sumFields(["meals"])) - parseFloat(lessCashWatch.toString()?.replace(/[^\d.-]/g, ''))));
  }

  const dueCompany = () => {
    let r = null;
    let lcw = 0;

    if (!isNumeric(lessCashWatch)) {
      lcw = 0;
    }
    else {
      lcw = parseFloat(lessCashWatch);
    }


    if (lcw == null || lcw.toString().length === 0) {
      r = Math.max(0, parseFloat(sumFields(["meals"])))
    }
    else {
      r = Math.max(0, (parseFloat(lcw.toString()?.replace(/[^\d.-]/g, '')) - parseFloat(sumFields(["meals"]))));
    }
    
    if (!isNumeric(r)) return 0;
    return r;
  }
  

  
  const [exModal, setExModal] = useState(false);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  
  const tryDeleteExpenseHeader = () => {

    if (existingExpenseHeader) {
      const payload = {
        expense_header: {
          id: existingExpenseHeader.id
        }
      }
      doDeleteFetch(deleteExpenseHeader, {payload: payload});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      navigate('/expense-reports')
    }
  }, [deleteLoaded]);

  const getCurrency = () => {
    if (!currencyWatch) return 'USD';

    return currencyWatch;
  }

  const [blobs, setBlobs] = useState({});

  const addBlob = (blob, index) => {
    setBlobs(old => {
      let oldHash = old;

      oldHash[index] = blob;

      return oldHash;
    });
    setValue(`expense_lines_attributes.${index}.expense_line_file_attributes._destroy`, null);
    setValue(`expense_lines_attributes.${index}.expense_line_file_attributes.blobname`, blob.blobname);
    setValue(`expense_lines_attributes.${index}.expense_line_file_attributes.filetype`, blob.filetype);
  }
  const removeBlob = (index) => {
    setBlobs(old => {
      let oldHash = old;

      delete oldHash[index];

      return oldHash;
      
    });
    
    setValue(`expense_lines_attributes.${index}.expense_line_file_attributes._destroy`, "1");
    // setValue(`expense_lines_attributes.${index}.expense_line_file_attributes.blobname`, null);
    // setValue(`expense_lines_attributes.${index}.expense_line_file_attributes.filetype`, null);
  }

  const [currentLineIndex, setCurrentLineIndex] = useState(null);
  const [currentExpenseLineID, setCurrentExpenseLineID] = useState(null);

  useEffect(() => {
    if (!uploadModalOpened) {
      setCurrentLineIndex(null);
    }
  }, [uploadModalOpened]);





  const onSubmit = (d) => {
    const data = getValues();

    data.submitnote = submitNote;




    const payload = {
      id: data.id,
      expense_header: data
    }
   


    doSubmitExpenseHeaderFetch(submitExpenseHeader, {payload: payload});
  }

  const onCreate = (d) => {
    const data = getValues();



    const payload = {
      id: data.id,
      expense_header: data
    }



    if (existingExpenseHeader) {

      doUpdateExpenseHeaderFetch(updateExpenseHeader, {payload: payload});
    }
    else {
      doCreateExpenseHeaderFetch(createExpenseHeader, {payload: payload});
    }
    

    
  }



  useEffect(() => {
    
    if (updateExpenseHeaderLoaded || createExpenseHeaderLoaded || submitExpenseHeaderLoaded) {

      if (blobs && Object.entries(blobs).length) {

        async function upload() {
          // *** UPLOAD TO AZURE STORAGE ***

          for (const [key, value] of Object.entries(blobs)) {
            const blob = value;
            await uploadFileToBlob(blob.file, blob.blobname);
          }

          
        }
    
        upload();
      }

      
      navigate('/expense-reports');
    }
  }, [blobs, updateExpenseHeaderLoaded, createExpenseHeaderLoaded, submitExpenseHeaderLoaded]);


  useEffect(() => {
    if (approveExpenseHeaderLoaded || returnExpenseHeaderLoaded) {

      navigate('/expense-reports/review');     
    }
  }, [approveExpenseHeaderLoaded, returnExpenseHeaderLoaded]);







  const employeeCanEdit = (!existingExpenseHeader || existingExpenseHeader?.expenseheaderstatusid === 1 || existingExpenseHeader?.expenseheaderstatusid === 6);


  return (
    <>

      {exModal &&
        <Modal focusTrap={false} toggleModal={() => setExModal(o => !o)}>


          <div className="padding-1">

            {!exchangeRates && 
              <div className='margin-top-3 margin-bottom-3'>
                Please select a reimbursement currency to view its exchange rates.
              </div>
            }
            {exchangeRates && 
              <div>
                <div className="bold font-2">{exchangeRates?.base_code}</div>
                  Updated {exchangeRates?.time_last_update_utc}

                  <div className="margin-top-2">
                    <div className="code">
                      <pre>
                        {JSON.stringify(exchangeRates?.rates, null, 2)}
                      </pre>
                    </div>
                  </div>
              </div>
            }
            
          </div>
          
        </Modal>
      }

      {deleteModalOpened &&
        <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Delete Expense Report</h2>
            <div onClick={toggleDeleteModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Are you sure you want to delete this expense report?
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
            <div onClick={tryDeleteExpenseHeader} className="danger button">
              {deleteLoading && <div className="spinner button-spinner"></div> }
              Delete
            </div>
          </div>
        </Modal>
      }

      
      {false && <div className="code">
        
        {/* <pre>
          currentProjectGroup: {JSON.stringify(currentProjectGroup, null, 2)}
        </pre> */}
        {/* <pre>
          projectGroupWatch: {JSON.stringify(projectGroupWatch, null, 2)}
        </pre> */}
        {/* <pre>
          projectIDWatch: {JSON.stringify(projectIDWatch, null, 2)}
        </pre> */}
        {/* <pre>
          projects: {JSON.stringify(projects, null, 2)}
        </pre> */}
        <pre>filteredCategories: {JSON.stringify(expenseCategories, null, 2)}</pre>
      </div>
      }
      {existingExpenseHeader?.submitnote &&
        <div className="section margin-bottom-1">
          <span className="font-075 bold">SUBMISSION NOTE: </span> {existingExpenseHeader?.submitnote}
        </div>
      }

      {existingExpenseHeader?.returnnote &&
        <div className="danger section margin-bottom-1">
          <span className="font-075 bold">RETURN NOTE: </span> {existingExpenseHeader?.returnnote}
        </div>
      }

      

      {returnModalOpened &&
        <Modal size="small" focusTrap={false} toggleModal={toggleReturnModal}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Return Report</h2>
            <div onClick={toggleReturnModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            {existingExpenseHeader?.returnnote &&
              <div className="margin-bottom-1">
                <span className="bold gray font-075">PREVIOUS RETURN NOTE: </span>
                <span> {existingExpenseHeader?.returnnote}</span>
              </div>
            }
            <textarea placeholder="Return note" value={returnNote} onChange={e => setReturnNote(e.target.value)}   />
          </div>

          <div className='modal-footer flex-cont align-center'>
            <input onClick={onReturn} type="submit" value="Submit" className={`${returnExpenseHeaderLoading ? 'disabled' : ''} margin-right-1 button`} />
            {returnExpenseHeaderLoading &&
              <div className="spinner margin-right-1"></div>
            }
            <div onClick={toggleReturnModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      

      {submitModalOpened &&
        <Modal size="small" focusTrap={false} toggleModal={toggleSubmitModal}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Submit Expense Report</h2>
            <div onClick={toggleSubmitModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            {existingExpenseHeader?.submitnote &&
              <div className="margin-bottom-1">
                <span className="bold gray font-075">PREVIOUS SUBMISSION NOTE: </span>
                <span> {existingExpenseHeader?.submitnote}</span>
              </div>
            }
            {/* <textarea placeholder="Submission note" value={andy} onChange={e => setAndy(e.target.value)}   /> */}
            <textarea name="submit_note" id="submit_note" placeholder="Submission note" value={submitNote} onChange={e => setSubmitNote(e.target.value)}   />
          </div>

          <div className='modal-footer flex-cont align-center'>
            <input onClick={onSubmit} type="submit" value="Submit" className={`${submitExpenseHeaderLoading ? 'disabled' : ''} margin-right-1 button`} />
            {submitExpenseHeaderLoading &&
              <div className="spinner margin-right-1"></div>
            }
            <div onClick={toggleSubmitModal} className={`${submitExpenseHeaderLoading ? 'disabled' : ''} button ghost`}>Cancel</div>
          </div>
        </Modal>
      }

      


      {uploadModalOpened && 
        <Modal focusTrap={false} toggleModal={toggleUploadModal} size="medium">

          <div className='modal-header flex-cont align-center'>
            <div className="flex-1">
              <h2 className="margin-bottom-1">{`Attach${inputsDisabled() ? 'ed' : ''} receipt`}</h2>
            </div>
            <div onClick={toggleUploadModal} className="button close ghost">
              <i className="fas fa-times"></i>
            </div>
          </div>


          <div className="padding-1">
            {/* <em className="gray">To be implemented.</em>  */}

            <Upload inputsDisabled={inputsDisabled()}  blobs={blobs} toggleUploadModal={toggleUploadModal} currentExpenseLineID={currentExpenseLineID} currentLineIndex={currentLineIndex} addBlob={addBlob} removeBlob={removeBlob} />
          </div>

          

          <div className="modal-footer align-center flex-cont">
            <div className="flex-1">
              {/* <div onClick={toggleUploadModal} className="ghost button">
                Cancel
              </div> */}
            </div>
            <div>

            </div>
          </div>
        </Modal>
      }

      
      
      {(true) && <>

        <form onSubmit={handleSubmit(onSubmit)}>

        {(false || (approveModalOpened)) &&
          <ApprovalModal onConfirmApproveClick={onConfirmApproveClick} trigger={trigger} register={register} errors={errors} viewAsUser={viewAsUser} expenseHeader={existingExpenseHeader} toggleApproveModal={toggleApproveModal} approveExpenseHeaderLoading={approveExpenseHeaderLoading} onApprove={onApprove} />
        }     

        <div className="flex-cont full margin-bottom-1">

        
          <input
            {...register(`id`, {
              validate: v => {
                return true;
              }
            })}  
            name={`id`}
            defaultValue={existingExpenseHeader?.id} 
            type="hidden"
          />

          <div className="section flex-1 margin-right-1">
            <table className="vertical">
              <tr>
                <th>Employee Name</th>
                <td>
                  {existingExpenseHeader?.id != null &&
                    <>
                      {existingExpenseHeader?.employeename} #{existingExpenseHeader?.employeenumber} 
                    </>
                  }
                  {existingExpenseHeader?.id == null  &&
                    <>
                      {viewAsUser?.fname} {viewAsUser?.lname} #{viewAsUser?.personnelnumber}
                    </>
                  }
                  
                  
                </td>
                <th>
                  
                </th>
                <td>
                  
                </td>
              </tr>
              <tr>
                <th>Project Name</th>
                <td  colSpan={3}>
                  <ProjectSelect viewAsUser={viewAsUser} inputsDisabled={inputsDisabled()} errors={errors} register={register} existingExpenseHeader={existingExpenseHeader} projectsLoading={projectsLoading} projects={projects} />
                </td>
              </tr>
              <tr>
                <th>Dates of travel</th>
                <td>
                  <input
                    {...register(`datestart`, {
                      validate: v => {
                        if (!v) return "Select a start date."
                        return true;
                      }
                    })}  
                    name={`datestart`}
                    defaultValue={existingExpenseHeader?.datestart} 
                    type="date"
                    className={`${(inputsDisabled()) && 'disabled-input'} `}
                  />
                  {errors?.datestart &&
                    <div className="error-cont">{errors?.datestart?.message}</div>
                  }
                </td>
                <th>thru</th>
                <td>
  
                  <input
                    {...register(`dateend`, {
                      validate: v => {
                        if (!v) return "Select an end date."
                        return true;
                      }
                    })}  
                    name={`dateend`}
                    defaultValue={existingExpenseHeader?.dateend} 
                    type="date"
                    className={`${(inputsDisabled()) && 'disabled-input'} `}
                  />
                  {errors?.dateend &&
                    <div className="error-cont">{errors?.dateend?.message}</div>
                  }
                </td>
                
              </tr>
            
            </table>
          </div>
          <div className=" section flex-1 margin-right-0">
            <table className="vertical">
              <tr>
                <th>Date Prepared</th>
                <td>
                  {existingExpenseHeader?.dateprepared && DateTime.fromISO(existingExpenseHeader?.dateprepared).toLocaleString()}
                  {!existingExpenseHeader?.dateprepared && DateTime.local().toLocaleString()}
                </td>
              </tr>
              {/* <tr>
                <th>Project #</th>
                <td  colSpan={3}>
                  <input type="text" name="" id="" />
                </td>
              </tr> */}
              <tr>
                <th>Destination</th>
                <td  colSpan={3}>
                  <input
                    {...register(`destination`, {
                      validate: v => {
                        if (!v) return "Enter a destination."
                        return true;
                      }
                    })}  
                    className={`${(inputsDisabled()) && 'disabled-input'} `}
                    name={`destination`}
                    defaultValue={existingExpenseHeader?.destination} 
                    type="text"
                  />
                  {errors?.destination &&
                    <div className="error-cont">{errors?.destination?.message}</div>
                  }
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="flex-cont full margin-bottom-1">
          <div className=" section flex-1 margin-right-0">
            <table className="vertical">
              <tr>
                <th>Purpose of Trip</th>
                <td colSpan={2}>
                  <input
                    {...register(`purpose`, {
                      validate: v => {
                        if (!v) return "Enter a purpose."
                        return true;
                      }
                    })}  
                    className={`${(inputsDisabled()) && 'disabled-input'} `}
                    name={`purpose`}
                    defaultValue={existingExpenseHeader?.purpose} 
                    type="text"
                  />
                  {errors?.purpose &&
                    <div className="error-cont">{errors?.purpose?.message}</div>
                  }
                </td>
              </tr>
              
              <tr>
                <th>Reimbursement Currency</th>
                <td>
                  {/* <pre>
                    {JSON.stringify(Object.entries(currencies), null, 2)}
                  </pre> */}
                  {/* <input
                    {...register(`exchangecurrency`, {
                      validate: v => {
                        return true;
                      }
                    })}  
                    name={`exchangecurrency`}
                    defaultValue={existingExpenseHeader?.exchangecurrency} 
                    type="text"
                  /> */}
                  <select
                    {...register(`exchangecurrency`, {
                      validate: v => {
                        if (!v) return "Select a currency."
                        return true;
                      }
                    })}  
                    className={`${(inputsDisabled()) && 'disabled-input'} `}
                    name={`exchangecurrency`}
                    defaultValue={existingExpenseHeader?.exchangecurrency || ""} 
                  >
                    <option value="" disabled>Select Currency</option>


          
                    { currencies && Object.entries(currencies).map(([key, value]) => {
                      return ( 
                        <option value={key}>
                          {key} - {value.name}
                        </option>
                      )
                    })}
                  </select>
                  {errors?.exchangecurrency &&
                    <div className="error-cont">{errors?.exchangecurrency?.message}</div>
                  }
                </td>
                <td>
                  {/* <pre>
                    {JSON.stringify(filteredCategories, null, 2)}

                    
                  </pre> */}
                  {/* <pre>
                    billableWatch: {JSON.stringify(billableWatch, null, 2)}
                  </pre> */}
                  <div className="margin-left-1 flex-cont align-center">
                    <label htmlFor="isbillable-yes" className='flex-cont align-center'>
                      <input 
                        defaultChecked={existingExpenseHeader?.isbillable} 
                        type="radio" 
                        value="true" 
                        name="isbillable" 
                        id="isbillable-yes" 
                        // inputsDisabled()
                        disabled={false}
                        {...register('isbillable')}
                      />
                      <div className="bold margin-left-half margin-right-1">Billable to Client</div>
                    </label>
                    
                    <label htmlFor="isbillable-no"  className='flex-cont align-center'>
                      <input 
                        defaultChecked={!existingExpenseHeader?.isbillable} 
                        type="radio" 
                        value="false" 
                        name="isbillable" 
                        id="isbillable-no" 
                        disabled={false}
                        {...register('isbillable')}
                      />
                      <div className="bold margin-left-half">Not Billable to Client</div>
                    </label>
                    
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td>
                  <div className="margin-left-1 margin-top-half">
                    <em>Please use separate forms for billable and non billable items</em>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        {/* <div className="section">
          <pre>
            {JSON.stringify(existingExpenseHeader, null, 2)}
          </pre>
        </div> */}

        <div className="flex-cont full">
          <div className=" section flex-1">

            {/* <div className="code">
              <pre>
                rows: {JSON.stringify(rows)}
              </pre>
              <pre>
                removedIndexes: {JSON.stringify(removedIndexes)}
              </pre>
              <pre>
                nonHiddens: {JSON.stringify(nonHiddens)}
              </pre>
            </div> */}

            <table className="expense-report basic wide compact">

              <thead>
                <tr  className='bluex'>
                  <th></th>
                  <th>
                    
                  </th>
                  <th style={{paddingLeft: '1em'}}>Date</th>
                  <th></th>
                  <th>Expense Description (Who/What/Where)</th>
                  <th>Category</th>
                  
                  <th>
                    Local Currency 
                    {false && <span>({})</span>}
                  </th>
                  <th>
                    Amt. in Local Currency
                    {false && <span>({})</span>}
                  </th>
                  <th>VAT / GST / C-TAX</th>
                  <th>
                    Est. Amt. in Reimbursement Currency
                    {true && <span> ({getCurrency()})</span>}  
                  </th>
                  {/* <th className='nowrap'>
                    <div>Country Currency</div> 

                    <div className="flex-cont font-075">
                      <div className="flex-1">
                        Currency
                      </div>
                      <div className="flex-1">
                        Amount
                      </div>
                    </div>
                    
                  </th> */}
                  {/* <th></th> */}
                  {/* <th className='width-money'>
                    Transpor-<br />
                    tation <span className="small-text">US$</span>
                  </th>
                  <th className='width-money'>Meals <span className="small-text">US$</span></th>
                  <th className='width-money'>Lodging / Other <span className="small-text">US$</span></th>
                  <th className='width-money'>Total Expenses <span className="small-text">US$</span></th> */}
                </tr>
              </thead>
              <tbody>
                {rows && rows.map((row, index) => {
                  const ii = row;
                  const hidden = removedIndexes.includes(ii);
                  const ccc = nonHiddens.indexOf(row) + 1;
                  // const taskFieldName = `budget_lines.${lineIndex}.budget_line_tasks.${ii}`;
                  // const taskErrors = errors?.budget_lines?.lineIndex?.budget_line_tasks?.[ii];


                  return (
                    <>
                      <ExpenseLine 
                        projectIDWatch={projectIDWatch}
                        existingExpenseHeader={existingExpenseHeader}
                        // billableWatch={billableWatch}
                        currentProjectGroup={currentProjectGroup}
                        viewAsUser={viewAsUser}
                        setCurrentExpenseLineID={setCurrentExpenseLineID}
                        currentLineIndex={currentLineIndex}
                        setCurrentLineIndex={setCurrentLineIndex}
                        exchangeRates={exchangeRates}
                        homeCurrency={getCurrency()}
                        inputsDisabled={inputsDisabled()}
                        errors={errors}
                        getValues={getValues}
                        expenseCategories={filteredCategories}
                        // mainAccounts={mainAccounts}
                        taxes={taxes}
                        existingExpenseLine={existingExpenseHeader?.expense_lines?.[row]}
                        currentBlob={blobs?.[index]}
                        toggleUploadModal={toggleUploadModal} count={ccc} setRemovedIndexes={setRemovedIndexes} hba={false} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setValue={setValue} control={control} register={register} index={index} hidden={hidden} />
                                    </>
                                    
                                  );
                                })}

                <tr>
                  <td colSpan={5}>
                    <div className="right-text">
                      <div className="error-cont">
                        {errors?.hasLines?.message}
                      </div>
                    </div>
                  </td>
                  <td colSpan={4}>
                    {!inputsDisabled() &&
                      <div onClick={addRowClicked} className="outline button margin-top-x" style={{boxSizing: 'border-box', width: '100%'}}>
                        <i className="margin-right-half fas fa-plus"></i>
                        Add Line
                      </div>
                    }
                  </td>
                  <td></td>
                  
                </tr>

                <tr className='totals input-font'>
                  <td style={{paddingRight: '1em'}} className='uppercase bold right-text' colSpan={9}>
                    Total Employee Expenses
                  </td>
                  <td className=''>
                    {sumFields(["meals"])?.toLocaleString('en-US', { style: 'currency', currency: getCurrency() })}
                  </td>
                  <td className='fit nowrap'>
                    
                  </td>
                  {/* <td>{sumFields(["transportation"])?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{sumFields(["meals"])?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{sumFields(["lodging"])?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{sumFields(["transportation", "meals", "lodging"])?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> */}
                </tr>
              </tbody>
              {/* <thead>
                <tr className='red'>
                  
                  <th style={{paddingRight: '1em'}} className='right-text' colSpan={3}>Expenses paid directly by HBA (Airfare/Company Credit Expenditures)</th>
                  <th className='nowrap'>
                    <div>Country Currency</div> 

                    <div className="flex-cont font-075">
                      <div className="flex-1">
                        Currency
                      </div>
                      <div className="flex-1">
                        Amount
                      </div>
                    </div>
                    
                  </th>
                  <th className='width-money'>
                    Transpor-<br />
                    tation <span className="small-text">US$</span>
                  </th>
                  <th className='width-money'>Meals <span className="small-text">US$</span></th>
                  <th className='width-money'>Lodging / Other <span className="small-text">US$</span></th>
                  <th className='width-money'>Total Expenses <span className="small-text">US$</span></th>
                </tr>
              </thead>
              <tbody>

                {["A", "B", "C"].map((letter, index) => {
                  {
                    return (
                      <ExpenseLine hba={true} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setValue={setValue} control={control} register={register} index={index} letter={letter} />
                    )
                  }
                })}

                <tr className='totals input-font'>
                  <td style={{paddingRight: '1em'}} className='uppercase bold right-text' colSpan={4}>
                    Total HBA DIRECT Expenses
                  </td>
                  <td>{sumFields(["transportation"], true)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{sumFields(["meals"], true)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{sumFields(["lodging"], true)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{sumFields(["transportation", "meals", "lodging"], true)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                </tr>
                <tr className='totalsx input-font'>
                  <td style={{paddingRight: '1em'}} className='uppercase bold right-text' colSpan={4}>
                    
                    Total Expenses (Employee Expenses + HBA Direct Expenses
                  </td>
                  
                  <td>{(sumFields(["transportation"]) + sumFields(["transportation"], true))?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{(sumFields(["meals"]) + sumFields(["meals"], true))?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{(sumFields(["lodging"]) + sumFields(["lodging"], true))?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                  <td>{(sumFields(["transportation", "meals", "lodging"]) + sumFields(["transportation", "meals", "lodging"], true))?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                </tr>
              </tbody> */}
            </table>

            
          </div>
        </div>

        <div className="margin-top-1 section">

          <div className="bold right-text">SUMMARY</div>

          <div className="flex-cont">
            <div className="flex-1">

            </div>
            <div>
              <table className="vertical">
              <tbody>
                  {/* <tr className='totals'>
                    <td colSpan={9}>
                      <div className="bold red padding-top-1 padding-bottom-half">
                        * I certify that the above statement represents actual and necessary expenses incurred while engaged on business of the company.
                      </div>
                    </td>
                  </tr> */}
                  {/* <tr className="totals">
                    <td></td>
                  </tr> */}




                  <tr>         
                    <th  className="border-left-2">
                      <div className="right-text">
                        Total Expenses
                      </div>
                      
                    </th>
                    <td className="border-right-2">
                      {(sumFields(["meals"]) + sumFields(["meals"], true))?.toLocaleString('en-US', { style: 'currency', currency: getCurrency() })}
                    </td>
                  </tr>
                  <tr>
                    <th className="border-left-2" >
                      <div className="right-text">
                        Less Cash Advance
                      </div>
                      
                    </th>
                    <td className="border-right-2">
                      <div className="nowrap flex-cont align-center">

                        <label htmlFor="" className={`${errors?.lesscashadvance ? 'error-label' : ''}`}>
                          $
                          <input  {...register(`lesscashadvance`, {
                            validate: v => {
                              
                              if (!v) return true
                              if (!isNumeric(v)) return "Enter a number."
                              // if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                              return true;
                            }
                          })}  
                          style={{fontSize: '.8rem'}}
                          name={`lesscashadvance`}
                          defaultValue={existingExpenseHeader?.lesscashadvance} 
                          type="text"  
                          placeholder="Amount"
                          className={`${(inputsDisabled()) && 'disabled-input'} `}
                          // className={`width-money ${(readOnly) && 'disabled-input'}`}  
                        />
                          
                        </label>

                      </div>
                      {errors?.lesscashadvance &&
                        <div className="error-cont">{errors?.lesscashadvance?.message}</div>
                      }
                    </td>
                  </tr>
                  {/* <tr>
                    <td colspan={3}>
                      
                    </td>
                    <td className="border-left-2" colSpan={4}>
                      <div className="right-text">
                        Less HBA Direct Exp. (Airfare/Amex)
                      </div>
                      
                    </td>
                    <td className="border-right-2">
                      {sumFields(["transportation", "meals", "lodging"], true)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr> */}
                  <tr>
                    <th className="border-left-2" >
                      <div className="right-text">
                        Amount due employee
                      </div>
                      
                    </th>
                    <td className="border-right-2">
                      {dueEmployee()?.toLocaleString('en-US', { style: 'currency', currency: getCurrency() })}
                    </td>
                  </tr>
                  <tr>
                    <th className="border-left-2 border-bottom-2">
                      <div className="right-text">
                        Amount due Company
                      </div>
                      
                    </th>
                    <td className="border-right-2 border-bottom-2">
                      {dueCompany()?.toLocaleString('en-US', { style: 'currency', currency: getCurrency() })}
                    </td>
                  </tr>
                </tbody>
            </table>
            </div>
            
          </div>
          
        </div>
        {employeeCanEdit &&
          <div className="margin-top-2 right-text">
            By submitting this form, I certify that the above statement represents actual and necessary expenses incurred while engaged on business of the company.
          
          </div>
        }

        <div className="flex-cont margin-top-2 ">
          <div className="flex-1 margin-right-2">
            <div>
              {existingExpenseHeader?.expenseheaderstatusid === 1 &&
                <div onClick={toggleDeleteModal} className={`${deleteLoading && 'disabled'} danger button`}>Delete</div>
              }
            </div>
          </div>


            {employeeCanEdit &&
              <>
                <div onClick={onCreate} className={`button margin-right-1 outline ${somethingLoading ? 'disabled' : ''}`}>
                  Save & Exit
                  {somethingLoading &&
                    <div className="spinner button-spinner"></div>
                  }
                </div>
                <div onClick={onSubmitClicked} className={`button ${somethingLoading ? 'disabled' : ''}`}>
                  Submit
                  {somethingLoading &&
                    <div className="spinner button-spinner"></div>
                  }
                </div>
              </>

            }
            
            {/* <pre>
              view_as_user_can_approve: {JSON.stringify(existingExpenseHeader?.view_as_user_can_approve, null, 2)}
            </pre> */}

            {(existingExpenseHeader?.needs_approval === true) &&
              <>

                {(existingExpenseHeader?.view_as_user_can_approve === true) && 
                  <div className='flex-cont align-center'>
                    {/* <div className='gray bold font-08 margin-right-2'>PM REVIEW:</div> */}
                    <div onClick={returnButtonClicked} className={`button margin-right-1 ${somethingLoading ? 'disabled' : ''}`}>
                      Return
                      {returnExpenseHeaderLoading &&
                        <div className="spinner button-spinner"></div>
                      }
                    </div>
                    <div onClick={onApproveButtonClicked} className={`button ${somethingLoading ? 'disabled' : ''}`}>
                      Approve
                      {approveExpenseHeaderLoading &&
                        <div className="spinner button-spinner"></div>
                      }
                    </div>
                  </div>
                }
                {(existingExpenseHeader && existingExpenseHeader?.view_as_user_can_approve === false) && 
                  <em className="gray">
                    {existingExpenseHeader?.statusname}
                  </em>
                }
              </>

            } 
          

            {/* {(existingExpenseHeader?.expenseheaderstatusid === 2) &&
              <>

                {(existingExpenseHeader?.view_as_user_can_approve === true) && <div className='flex-cont align-center'>
                  <div className='gray bold font-08 margin-right-2'>PM REVIEW:</div>
                  <div onClick={returnButtonClicked} className={`button margin-right-1 ${somethingLoading ? 'disabled' : ''}`}>
                    Return
                    {returnExpenseHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                  <div onClick={onApproveButtonClicked} className={`button ${somethingLoading ? 'disabled' : ''}`}>
                    Approve
                    {approveExpenseHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                </div>}
              </>

            }
            {(existingExpenseHeader?.expenseheaderstatusid === 3) &&
              <>

                {(viewAsUser.isexpenseapprover) && <div className='flex-cont align-center'>
                  <div className='gray bold font-08 margin-right-2'>OFFICE REVIEW:</div>
                  <div onClick={returnButtonClicked} className={`button margin-right-1 ${somethingLoading ? 'disabled' : ''}`}>
                    Return
                    {returnExpenseHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                  <div onClick={onApproveButtonClicked} className={`button ${somethingLoading ? 'disabled' : ''}`}>
                    Approve
                    {approveExpenseHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                </div>}
              </>

            } 

            {(existingExpenseHeader?.expenseheaderstatusid === 8) &&
              <>

                {(viewAsUser.isfinalexpenseapprover) && <div className='flex-cont align-center'>
                  <div className='gray bold font-08 margin-right-2'>FINAL REVIEW:</div>
                  <div onClick={returnButtonClicked} className={`button margin-right-1 ${somethingLoading ? 'disabled' : ''}`}>
                    Return
                    {returnExpenseHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                  <div onClick={onApproveButtonClicked} className={`button ${somethingLoading ? 'disabled' : ''}`}>
                    Approve
                    {approveExpenseHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                </div>}
              </>

            } */}


          
        </div>

        </form>

        
      </>}

      
      


      {/* {existingExpenseHeader &&
        <div>
          <pre>
            {JSON.stringify(existingExpenseHeader, null, 2)}
          </pre>
        </div>
      } */}

      {errorModalOpened &&
        <Modal size="small" focusTrap={false} toggleModal={toggleErrorModal}>
          <div className="modal-header flex-cont align-center">
            {/* <h2 className="flex-1">Return Budget</h2> */}

            <div className="flex-1"></div>
            <div onClick={toggleErrorModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            There are some issues with your expense report. Please fix the highlighted issues and try again.
          </div>

          <div className='modal-footer flex-cont align-center'>
           
          </div>
        </Modal>
      } 

      {(approveExpenseHeaderLoading) &&
        <Modal size="small" focusTrap={false} toggleModal={() => {}}>
          {/* <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Submit Expense Report</h2>
            <div onClick={toggleSubmitModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div> */}

          <div className="padding-1 margin-top-2">
            {approveExpenseHeaderLoading &&
              <div className="spinner margin-bottom-2"></div>
            }
            <div>

              {existingExpenseHeader?.is_on_last_step && <>Posting expense report to D365...</>}
              {!existingExpenseHeader?.is_on_last_step && <>Approving expense report...</>}
            </div>
            
          </div>

        </Modal>
      }
    </>
  )
}


function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
    currentUser: state.authState.currentUser,
    viewAsUser: state.authState.viewAsUser
  };
} 

export default connect(mapState)(ExpenseForm);