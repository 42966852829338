import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { getMyExpenseHeaders, getApprovalSteps, getStatuses } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { getReviewCount } from '../../../redux/manager/budgetHeaders/budgetHeaderActions';
import { DateTime } from 'luxon';
import {
  Link, useNavigate,
} from 'react-router-dom';

import { usePrevious } from '../../../hooks/usePrevious';

import Modal from '../../../components/Modal';
import TH from '../TH';
import { useSortExpenseReports, toNum, isNumber } from '../useSortExpenseReports';
import { getHasAssignedProjects } from '../../../redux/projects/projectActions';
import CheckBoxes from '../../../components/CheckBoxes';



const ExpenseHome = ({currentCompany, children}) => {

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  useEffect(() => {
    fetchProjects();
    fetchStatuses();
  }, [currentCompany]);

  useEffect(() => {
    fetchExpenseHeaders();   
  }, [currentCompany, selectedStatuses]);


  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();

  const fetchProjects = () => {
    doProjectsFetch(getHasAssignedProjects);
  }


  const [expenseHeaders, expenseHeadersLoading, expenseHeadersLoaded, expenseHeadersError, doExpenseHeadersFetch, setExpenseHeadersResult] = useApi();

  const [statuses, statusesLoading, statusesLoaded, statusesError, doStatusesFetch, setStatusesResult] = useApi();

  const fetchExpenseHeaders = () => {
    doExpenseHeadersFetch(getMyExpenseHeaders, {query: {"statuses[]": selectedStatuses}});
  }

  const fetchStatuses = () => {
    doStatusesFetch(getStatuses);
  }

  const {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked} = useSortExpenseReports();

  useEffect(() => {
    if (expenseHeaders) {
      setSorted(sortByColumn([...expenseHeaders], sortedColumn, sortDirection));
    }
  }, [sortedColumn, expenseHeaders, sortDirection]);

  useEffect(() => {
  }, [sorted]);

  const [returnNote, setReturnNote] = useState(null);
  const [returnModalOpened, setReturnModalOpened] = useState(false);

  const returnNoteClicked = (note) => {
    setReturnNote(note);
    toggleReturnModal();
  }

  const toggleReturnModal = () => {
    setReturnModalOpened(o => !o);
  }

  const tabCount = status => {
    return null;
  }

  const disableStatusCheckBoxes = expenseHeadersLoading || statusesLoading;
  

  return (
    <>
      {returnModalOpened &&
        <Modal size="small" focusTrap={false} toggleModal={toggleReturnModal}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Return Note</h2>
            <div onClick={toggleReturnModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            {returnNote}
          </div>

          <div className='modal-footer flex-cont align-center'>
            <div onClick={toggleReturnModal} className="button">Close</div>
          </div>
        </Modal>
      }

      <div className="flex-cont align-center">
        <h1 className="flex-cont flex-1">
          <div className="margin-right-half medgray">{currentCompany}</div>
          My Expense Reports
        </h1>

        {projectsLoading &&
          <Link to="/expense-reports/new" className={`button disabled`}>
            New Report
            <div className="button-spinner spinner"></div>
          </Link>
        }
        {!projectsLoading && !projects &&
          <div className='flex-cont align-center'>
            <div className='gray font-08 margin-right-0half'>You must be assigned a project to create an expense report.</div>
            <div className="button disabled">New Report</div>
            
          </div>
        }
        {projects &&
          <Link to="/expense-reports/new" className="button">
            New Report
          </Link>
        }
        
      </div>  
      

      <div className="content margin-top-1">

        {/* <pre>
          {JSON.stringify(sorted, null, 2)}
        </pre> */}

    

      {/* <div className="code">
        <pre>
          {JSON.stringify(statuses, null, 2)}
        </pre>
      </div> */}

      

      {/* {statuses &&
        <div className={`margin-bottom-2 margin-right-2 check-boxes ${expenseHeadersLoading ? 'disabled' : ''}`}> 

          {statuses.map(status => 
              <CheckBoxes disabled={disableStatusCheckBoxes} label={status.name} name={status.id} boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
          )}
        </div>
      } */}
      

      <div>
        {expenseHeadersError &&
          <div className='gray'>
            There was an error fetching your expense reports.
          </div>
        }

        {!expenseHeadersLoading && (sorted && sorted.length === 0) &&
          <div  className='gray'>
            You do not currently have any expense reports.
          </div>
        }

        {expenseHeadersLoading &&
          <div className="spinner"></div>
        }
      </div>
      


      {/* <pre>
        {JSON.stringify(sorted, null, 2)}
      </pre> */}
      {!expenseHeadersLoading && sorted && sorted.length !== 0 &&
        <table className="basic">
          <thead>
            <tr>
              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="number" sortedColumn={sortedColumn} label="Number" columnClicked={columnClicked} />
              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="projectid" sortedColumn={sortedColumn} label="Project ID" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="statusname" sortedColumn={sortedColumn} label="Status" columnClicked={columnClicked} />
              

              <th>
                Approver
              </th>

              <th className="fit nowrap">
                Note
              </th>

              <TH direction={sortDirection} classNames={["nowrap", "fit"]} column="projectname" sortedColumn={sortedColumn} label="Project Name" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="destination" sortedColumn={sortedColumn} label="Destination" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="datestart" sortedColumn={sortedColumn} label="Start Date" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="dateend" sortedColumn={sortedColumn} label="End Date" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["nowrap", "fit"]} column="totalexpenses" sortedColumn={sortedColumn} label="Total Expenses" columnClicked={columnClicked} />

              <th></th>
            </tr>
          </thead>
          <tbody>
            {sorted && sorted.map( expenseHeader => 
              <tr>

                <td className="fit nowrap">
                  <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}>
                    {expenseHeader.number}
                  </Link>
                </td>
                
                
                <td className="fit nowrap">
                  <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}>
                    {!expenseHeader.projectid &&
                      <>
                        <span>NO PROJECT</span>
                      </>
                    }
                    {expenseHeader.projectid &&
                      <>{expenseHeader.projectid}</>
                    }
                  </Link>
                </td>

                <td className="fit nowrap">
                  <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}>
                    <>
                    {expenseHeader.statusname &&
                      <div className="status-badge-cont">
                        <div className={`budget status-badge ${expenseHeader.statusname.toLowerCase()}`}>
                          {expenseHeader.statusname.toUpperCase() }
                        </div>
                      </div>
                    }
                    
                    {/* {!project.budgetHeaderStatus &&
                      <div className="bold font-075 gray">NEEDS BUDGET</div>
                    } */}
                  </>
                  </Link>
                </td>

                <td>
                  {expenseHeader.approvers?.map(approver => 
                    <div className="nowrap">{approver}</div>
                  )}
                </td>

                <td className="center fit nowrap">
                  {expenseHeader?.expenseheaderstatusid?.toString() === "6" && expenseHeader.returnnote &&
                    <i onClick={() => returnNoteClicked(expenseHeader.returnnote)} className="fas fa-pencil-alt circle font-075 button danger red"></i>
                  }
                </td>

                <td className="nowrap">
                  {/* <div>
                    Status: {expenseHeader?.budgetHeaderStatus?.toUpperCase()}
                  </div> */}
                  {/* <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}> */}
                    {expenseHeader.projectname}
                  {/* </Link> */}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.destination}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.datestart ? DateTime.fromISO(expenseHeader.datestart).toLocaleString() : ""}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.dateend ? DateTime.fromISO(expenseHeader.dateend).toLocaleString() : ""}
                </td>
                <td className="fit center">
                  {expenseHeader.totalexpenses !== null ? parseFloat(expenseHeader.totalexpenses)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'  }
                </td>
                <td className='full'></td>
              </tr>
            )}
          </tbody>
        </table>
      }

      </div>
    </>
  )
}



const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(ExpenseHome);
