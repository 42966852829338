import { andyFetch, actionReturn } from '../../api';
import { EmailClient, KnownEmailSendStatus } from '@azure/communication-email'

export const getEntries = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getManagedEntries = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/managed";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const createEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};
export const submitTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries/submit_timesheet";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('submitTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};
export const saveTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries/save_timesheet";

    try {
      // const response = await andyFetch(url, params, "POST");
      // const json = await response.json();
      // 
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('saveTimesheet error.');
      }
      // if (response.ok) {
        
      //   return actionReturn(json);

      // } else {
        
      //   return json.message;
        
      //   // throw new Error('saveTimesheet error.');
      // }
    }
    catch (e) {

      
    }
  }
};

export const saveNote = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/save_note";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('saveNote error.');
      }
    }
    catch (e) {
      
    }
  }
};



const toHTML = noti => {
  if (!noti) return "";

  if (noti.percent >= 100) {
    return(`
      <div>
        <p>
          Dear ${noti.first_name} ${noti.last_name},
        </p>
        <p>
          <strong style="font-size: 1.25em">${noti.percent}%</strong> of the budgeted hours have been consumed for project <strong>${noti.projectid} - ${noti.projectname}</strong> in ${noti.company}. Employee will now be restricted from submitting hours against this project. Please revise the budget at the link below.

          <ul>
            <li>Budgeted Hours: ${noti.budgeted}</li>
            <li>Consumed Hours: ${noti.consumed}</li>
          </ul>
        <p/>
        <p> 
          The link to this project's budget can be found here: <a href="${process.env.REACT_APP_FRONTEND_URL}/budgeting/projects/${noti.projectid}">${process.env.REACT_APP_FRONTEND_URL}/budgeting/projects/${noti.projectid}</a>
        </p>

        <br />
        <br />

      </div>
        
    `)
  }

  return(`

    <div>
      <p>
        Dear ${noti.first_name} ${noti.last_name},
      </p>
      <p>
        <strong style="font-size: 1.25em">${noti.percent}%</strong> of the budgeted hours have been consumed for project <strong>${noti.projectid} - ${noti.projectname}</strong> in ${noti.company}.

        <ul>
          <li>Budgeted Hours: ${noti.budgeted}</li>
          <li>Consumed Hours: ${noti.consumed}</li>
        </ul>
      <p/>
      <p> 
        The link to this project's budget can be found here: <a href="${process.env.REACT_APP_FRONTEND_URL}/budgeting/projects/${noti.projectid}">${process.env.REACT_APP_FRONTEND_URL}/budgeting/projects/${noti.projectid}</a>
      </p>

      <br />
      <br />

    </div>
  `)
}

export const trySendEmail = async (noti) => {
  const connectionString = "endpoint=https://kronoscommunicationservice.unitedstates.communication.azure.com/;accesskey=/vsK9rhTNvy1nkNE58uZJaMX7nnsvfmaw65tvRTKYCHV3IkgcK/sBYfw63cWYwlVrjMGNtk9NVY2uBbIqGbH/g==";

  const client = new EmailClient(connectionString);

  let subject = "";

  if (noti.percent >=  100) {
    subject = `Submitting hours now restricted: ${noti.percent}% consumed for ${noti.projectid} - ${noti.projectname}`
  }
  else {
    subject = `Budget warning: ${noti.percent}% consumed for ${noti.projectid} - ${noti.projectname}`
  }

  const emailMessage = {
      senderAddress: "DoNotReply@873963c4-576a-45ca-be3d-72bb48dd1f40.azurecomm.net",
      content: {
          subject: subject,
          plainText: `Dear ${noti.first_name} ${noti.last_name}, consumed hours for ${noti.projectid} has reached ${noti.percent}`,
          html: toHTML(noti)
      },
      recipients: {
          to: [
            { address: noti.email },
          ],
          bcc: [
            { address: "asimon@axseva.com" },
          ]
      },
  };
  

  let poller;

  try {
    poller = await client.beginSend(emailMessage);
  } catch (error) {

    console.log('error', error)
    // setError("An error occurred. Please try again.");
    // alert("An error occurred. Please try again.")
  }

  console.log('poller', poller)

  let result;

  try {
    result = await poller.pollUntilDone();
  } catch (error) {

    console.log('error2', error)
    // alert("An error occurred. Please try again.")
    // setError("An error occurred. Please try again.");
  }


  console.log('email result', result)
  // setError(null);
  // setLoading(false);
  // setLoaded(true);

}



export const approveLine = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/approve";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        const fullJSON = actionReturn(json).fullJSON;
        const email_notis = fullJSON.email_notis;

        if (process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod") {
          if (email_notis != null && email_notis.length !== 0) {
            email_notis.forEach(noti => trySendEmail(noti))
          }
        }
        
        

        // alert(JSON.stringify(actionReturn(json), null, 2));


        return actionReturn(json);

      } else {
        throw new Error('approveLine error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const returnLine = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/reject";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('returnLine error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const deleteEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries/delete";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('deleteEntry error.');
      }
    }
    catch (e) {

      
    }
  }
};

export const setEntries = (entries) => {
  return dispatch => {
    dispatch({
      type: "SET_ENTRIES",
      entries: entries
    });
  }
}

export const addEntry = (entries) => {
  return dispatch => {
    dispatch({
      type: "ADD_ENTRY",
      entries: entries
    });
  }
}
export const removeEntry = (entryId) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_ENTRY",
      entryId: entryId
    });
  }
}
export const removeEntries = (ids) => {
  // alert(JSON.stringify(ids, null, 2))
  return dispatch => {

    ids.forEach(id => {
      dispatch({
        type: "REMOVE_ENTRY",
        entryId: id
      });
    })
    
  }
}
export const destroyEntries = (ids) => {
  // alert(JSON.stringify(ids, null, 2))
  return dispatch => {

    ids.forEach(id => {
      // alert(id.toString())
      dispatch({
        type: "DESTROY_ENTRY",
        entryId: id
      });
    })
    
  }
}
export const changeEntry = (entry) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ENTRY",
      entry: entry
    });
  }
}