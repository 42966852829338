import React, { useLayoutEffect, useState, useEffect, useRef} from 'react';
import logo7 from '../images/HBA_logo_2025.png';
import miniLogo from '../images/logo1.png';
import { NavLink, Link, Outlet, useLocation } from 'react-router-dom';
import store from '../redux/store';
import { connect } from 'react-redux';
import useScrollPosition from '../hooks/useScrollPosition';
import { unsetTokenResponse } from '../redux/authActions';
import { changeCurrentCompany } from '../redux/companies/companyActions';
import { useNavigate } from 'react-router-dom';
import { getCompanies } from '../redux/admin/companyActions';

import { testAPI } from '../redux/manager/budgetHeaders/budgetHeaderActions';
import { useApi } from '../api';
import Modal from '../components/Modal';
import { getUsers, changeViewAsUser } from '../redux/admin/userActions';
import Pagination from '../components/Pagination';
import { setFullscreenLoading2 } from '../redux/ui/uiActions';
import FullScreenSpinner from './FullScreenSpinner';
import { getLogoName } from '../utils';
import { useClickOutside } from '../hooks/useClickOutside';
import { trySendEmail } from '../redux/alphaTimesheets/alphaTimesheetActions'


const Menu = ({children, opened, setOpened}) => {

  const onClickOutside = () => {
    setOpened(false);
  }


  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, onClickOutside);

  // useEffect(() => {
  //   if (opened) {
  //     document.addEventListener("mousedown", handleClickOutside, false);
  //   }
  //   else {
  //     document.removeEventListener("mousedown", handleClickOutside, false);
  //   }
  // }, [opened]);


  
  // useEffect(() => {



  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside, false);
  //   };
  // }, []);

  const handleClickOutside = event => {
        // alert(timesheetTooltipIsOpen)
    // hideTooltip();
    // // alert(timesheetTooltipIsOpen);
    
    
    // if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //   setOpened(false);
    // }
  }; 

  return (
    <>
      {opened &&
        <div ref={wrapperRef} className={`menu`}>
          {children}
        </div>
      }
    </>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, 300);
      return () => clearTimeout(id);
    }
  }, [delay]);
}

const ViewAsModal = ({toggleModal}) => {

  const [hasTyped, setHasTyped] = useState(false);
  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  const [result2, loading2, loaded2, error2, doFetch2, setResult2, pagy2] = useApi();


  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);

  const navigate = useNavigate("/");

  const fetchUsers = () => {
    doFetch(getUsers, {query: {all_companies: true, search: search, page: page}});
  }

  useEffect(() => {
    if (hasTyped) {
      fetchUsers();
    }

    
  }, [page, hasTyped]);
  
  useEffect(() => {
    // fetchUsers();
  }, []);

  const userClicked = workerrecid => {
    doFetch2(changeViewAsUser, {query: {workerrecid: workerrecid}})
  } 

  useEffect(() => {
    if (loaded2) {
      navigate("/");
      toggleModal();
    } 
  }, [loaded2]);


  const handlePageChange = (p) => {
    setPage(p);
  };

  const searchInputChanged = e => {
    setHasTyped(true);
    const value = e.target.value;
    setSearch(value);
  }

  const [delay, setDelay] = useState(null)

  useInterval(() => {  
    doFetch(getUsers, {query: {all_companies: true, search: search, page: 1}});
  }, delay);

  useEffect(() => {

    if (hasTyped) {
      setDelay(Date.now());
    }
    
  }, [search])



  return (
    <Modal size="large" toggleModal={toggleModal} focusTrap={false}>
      <div className='modal-header flex-cont align-center'>
        <div className="flex-1">
          <h1 className="margin-bottom-1">Select user:</h1>
        </div>
        <div onClick={toggleModal} className="button close ghost">
          <i className="fas fa-times"></i>
        </div>
      </div>
      <div className="padding-1">
        <div className="seearch-cont margin-bottom-1">
          <input onChange={e => searchInputChanged(e)} placeholder="Search users..." type="text" name="" id=""/>
        </div>
        <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        {!hasTyped &&
          <em>Begin typing to search for users</em>
        }
        {loading && <div className="spinner"></div> }
        {!loading && hasTyped &&
          <table className="basic">
            <tr>
              <th></th>
              <th className="left-text ">Role</th>
              <th>First</th>
              <th>Last</th>
              
              
              <th>Email</th>

              <th>ID</th>
              <th>Company</th>
              <th className="center-text">Enabled</th>
              <th></th>
            </tr>
            {result?.users && result?.users?.map(user => {
              return (
                <tr>
                  <td  className="fit nowrap">
                    <div  onClick={() => userClicked(user.workerrecid)} className={`button ${loading2 && 'disabled'}`}>
                      Select
                      {/* {loading2 && <div className="spinner button-spinner "></div>  } */}
                    </div>
                  </td>
                  <td className="left-text uppercase bold font-075 fit">{user.rolename}</td>
                  <td className="fit nowrap">{user.fname}</td>
                  <td className="fit nowrap">{user.lname}</td>
                  
                  
                  <td className="fit nowrap">{user.email}</td>
                  <td className="fit nowrap">{user.personnelnumber}</td>
                  <td className="fit nowrap">{user.company}</td>
                  <td className="center-text">{user.enabled ? "Yes" : "No"}</td>
                </tr>
              )
            })}
          </table>
        }
        <div className="margin-top-1"></div>
        <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        
      </div>
    </Modal>
  )
}

const MainNav = ({fullscreenLoading2, currentCompany, viewAsUser, dynamicsToken, currentAccount, currentUser, trySignout, submittedCount}) => {

  const [hamOpened, setHamOpened] = useState(false); 
  const [scrollPosition, isTop] = useScrollPosition();

  const hamClicked = () => {
    setHamOpened(o => !o);
  }

  const [submenuOpened, setSubmenuOpened] = useState(false);

  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [officeMenuOpened, setOfficeMenuOpened] = useState(false);
  const [notiMenuOpened, setNotiMenuOpened] = useState(false);

  const navigate = useNavigate("/");

  const logoutClicked = async () => {
    // await store.dispatch(unsetTokenResponse());

    trySignout();
    navigate("/");
  }


  // const trySignout = () => {
  //   try {
  //     msalInstance.logout();
  //   } catch (err) {
  //       // handle error
  //       alert('trySignout error');

  //       
  //   }
  // }

  const [viewAsModalOpened, setViewAsModalOpened] = useState(false);

  const toggleViewAsModal = () => {
    setViewAsModalOpened(o => !o)
  }

   
  const [companies, companiesLoading, companiesLoaded, companiesError, doCompaniesFetch, setCompaniesResult] = useApi();
  const fetchCompanies = () => {
    doCompaniesFetch(getCompanies);
  }

  useEffect(() => {
    // fetchCompanies();
  }, [])


  const [va, setVa] = useState(localStorage.getItem('viewAsUserID'));

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const testAPIClicked = () => {
    doFetch(testAPI, {payload: {token: dynamicsToken?.access_token}})
  }

  const [changeCompResult, changeCompLoading, changeCompLoaded, changeCompError, doChangeCompFetch, setChangeCompResult] = useApi();
  
  const changeCurrentCompanyClicked = company => {
    store.dispatch(setFullscreenLoading2(true));
    doChangeCompFetch(changeCurrentCompany, {query: {company: company}})
  }

  useEffect(() => {
    if (changeCompLoaded || changeCompError) {
      store.dispatch(setFullscreenLoading2(false));
      setOfficeMenuOpened(false);
    }
  }, [changeCompLoaded, changeCompError])

  const officeMenuClicked = () => {
    if (viewAsUser?.my_companies?.length > 1) {
      setOfficeMenuOpened(true);
    }
    
  }

  const testNoti = {
    submitted: 100,
    budgeted: 200,
    percent: .5,
    first_name: "Cecilia",
    last_name: "Cai",
    email: "cecilia.cai@hba.com",
    projectid: "54321.00",
    projectname: "Test Project Name",
    company: "HBCH",
  }
  // "cecilia.cai@hba.com"
  // "asimon@axseva.com"

  const isAdmin = currentUser?.roleid === 2;

  const currentCompanyID = localStorage.getItem('currentCompanyID');

  const hasMultipleCompanies = !!(viewAsUser?.my_companies?.length > 1);

  const currentLogo = () => {
    const name = getLogoName(currentCompany);
    if (name === "HBA") return "";
    return name;
  }

  return(
    <nav className={`main ${isTop ? '' : 'scrolled'} ${isAdmin ? 'admin-main-nav' : ''}     `}>

      {/* <div onClick={testAPIClicked} className="button">
        TEST API
      </div> */}

      
    
    {viewAsModalOpened &&
      <ViewAsModal toggleModal={toggleViewAsModal} />
    }


      <div className="flex nav-inner">
      <div className="desktop-only narrow-2 flex-cont align-center full">
        <div className="logo-cont">
          <Link to="/timesheets" className="flex-cont align-center">
            <img src={logo7} alt="Logo" />

            {currentLogo() &&

              <div className="company-logo">
                <img src={`/${currentLogo()}.png`} />
              </div>
            }
          </Link>
        </div>

        <div className=''>
          {process.env.REACT_APP_DEPLOYMENT_SLOT === "prod" &&
            <div className="padding-1 env-banner prod">

            </div>
          }
          {process.env.REACT_APP_DEPLOYMENT_SLOT === "test" &&
            <div className="padding-1 env-banner test">
              <i className="fas fa-exclamation-triangle margin-right-1"></i>
              TEST ENV.
            </div>
          }
          {process.env.REACT_APP_DEPLOYMENT_SLOT === "dev" &&
            <div className="padding-1 env-banner dev">
              <i className="fas fa-exclamation-triangle margin-right-1"></i>
              DEV ENV.
            </div>
          }
        </div>
        

        <div className="nav-links-cont flex-cont">

          
          {/* <div className="button margin-right-1" onClick={testAPIClicked}>
            TEST API
          </div>


          <div className="button" onClick={() => localStorage.setItem('viewAsUserID', '5637146088')}>
            SET VA
          </div> */}

          {/* <div>
            {JSON.stringify(viewAsUser?.my_companies)}
          </div> */}

          {false && 
            <>

              <div>
                {currentCompanyID}
              </div>

              <div onClick={() => alert(JSON.stringify(viewAsUser, null, 2))} className="button"></div>
            </>
          }
          <div onClick={() => officeMenuClicked()}   className={`${hasMultipleCompanies ? '' : 'nonclicky'} office menu-cont margin-right-2`}>
            <div className="bold flex-cont align-center">
              <div className="flex-cont align-center">
                <i className="lightgray font-1 fas fa-building margin-right-half"></i>
                {currentCompany}
                {hasMultipleCompanies &&
                  <i className="fas fa-caret-down margin-left-half"></i>
                }
              </div>
              {/* <i className="margin-left-half fas fa-caret-down"></i> */}
            </div>
            {/* <Menu opened={officeMenuOpened} setOpened={setOfficeMenuOpened}>
              <>
                {viewAsUser?.my_companies?.map(company => (
                    <div onClick={() => changeCurrentCompanyClicked(company)} className="item">{company}</div>
                  ))
                }
              </>
            </Menu> */}

            

            {(hasMultipleCompanies && officeMenuOpened) &&
              <Menu opened={officeMenuOpened} setOpened={setOfficeMenuOpened}>
                <>
                  {viewAsUser?.my_companies?.map(company => (
                      <div onClick={() => changeCurrentCompanyClicked(company)} className="item">{company}</div>
                    ))
                  }
                </>
              </Menu>
            }
          </div>

{/* div  onClick={() => setNotiMenuOpened(o => !o)}  */}
          {/* <Link to="/notifications" className="black menu-cont noti-cont margin-right-2">
            <i className="fas fa-bell"></i>
            <div className="noti-number">
              {submittedCount}
            </div>

            {notiMenuOpened &&
              <Menu opened={notiMenuOpened} setOpened={setNotiMenuOpened}>
                <h2>Alerts</h2>
              </Menu>
            }
            
          </Link> */}
          


          <div onClick={() => setUserMenuOpened(o => !o)} className="menu-cont flex-cont align-center">

            <div className="name-cont margin-right-half">
              <div className="bold">
                {currentUser?.fname} {currentUser?.lname}
              </div>
              <div className="right-text font-075">
                {currentUser?.rolename}
              </div>
            </div>


            <div className="avatar-cont">
              <div className="initials">
                {currentUser?.fname[0]}
                {currentUser?.lname[0]}
              </div>
            </div>

            {userMenuOpened &&

              <Menu opened={userMenuOpened} setOpened={setUserMenuOpened}>
                <>
                  {/* <div onClick={() => alert("To be implemented.")} className="item">Switch User</div> */}
                  <div onClick={logoutClicked} className="item">Logout</div>
                </>
              </Menu>
            }

          </div>


        </div>

      



      </div>   



      <div className="mobile-only flex-cont align-center justify-center full">

        
        <div className="mobile-nav-logo-cont">
          <Link to="/">
            <img src={logo7} alt="Logo" />
          </Link>
        </div>
        

        <div className="mobile-nav-right flex-1">
          
        </div>

        <div className={`ham-menu ${hamOpened ? 'opened' : ''}`}>
          <i onClick={hamClicked} className="ham-close fas fa-times"></i>

          <ul>
            
            <li>
              <Link onClick={hamClicked} to="/">Dashboard</Link>
            </li>
            <li>
              <Link onClick={hamClicked} to="/activity">Activity</Link>
            </li>
            <li>
              <Link onClick={hamClicked} to="/fundraising">Fundraising</Link>
            </li>
            
            <li>
              <Link to="/">Dashboard</Link>
            </li>
          </ul>
        </div>
      </div>
      </div>

      {isAdmin && viewAsUser &&
        <div className="view-as flex-cont">
          <div className="margin-right-1 flex-cont">
            <div className="bold margin-right-1">ACTING AS </div> {viewAsUser.fname} {viewAsUser.lname} ({viewAsUser.rolename}) 

            {currentUser.roleid === 2 && 
              <div className='margin-left-1'> {viewAsUser.workerrecid}</div>
            
            }
          </div>
          <div onClick={toggleViewAsModal} className="button">
            Change
          </div>

          {(process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" && currentUser.roleid === 2) && 
            <div onClick={() => trySendEmail(testNoti)} className="margin-left-2 button">
              ADMIN: Test Budget Alert
            </div>
          }
          
        </div>
      }

      {fullscreenLoading2 &&
        <FullScreenSpinner message="Loading company..." />
      }
    </nav>
  )
}

function mapState(state) {
  return { 
    currentAccount: state.authState.currentAccount,
    currentUser: state.authState.currentUser,
    viewAsUser: state.authState.viewAsUser,
    currentCompany: state.authState.currentCompany,
    submittedCount: state.timesheetState.submittedCount,
    dynamicsToken: state.authState.dynamicsToken,
    fullscreenLoading2: state.uiState.fullscreenLoading2,
  };
} 

export default connect(mapState)(MainNav);
